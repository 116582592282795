import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import {
  BackgroundImage,
  Spacer,
  Textarea
} from "components"
import { colors } from "theme"
import { getIcon } from "utils"

const PostPreview = props => {
  const {
    date,
    image,
    headline,
    postType,
    text,
    url
  } = props

  const formattedDate = new Date(date)
    .toDateString()
    .split(" ")
    .slice(1)
    .join(" ")

  return (
    <>
      <PostContainer>
        <Grid container>
          {image && (
            <Grid item xs={12} md={4}>
              <Link to={url}>
                <ImageContainer>
                  <BackgroundImage image={image} />
                </ImageContainer>
              </Link>
            </Grid>
          )}
          <Grid item xs={12} md={image ? 8 : 12}>
            <PostDetails>
              {date && <Item>{formattedDate}</Item>}
              <Spacer mb={5} />
              {headline && (
                <Spacer mb={5}>
                  <Link to={url}>
                    <Typography variant="h5" children={headline} />
                  </Link>
                </Spacer>
              )}
              <Excerpt>
                <Spacer mb={20}>
                  <Textarea className="excerpt-text" content={text} />
                </Spacer>
              </Excerpt>
              <Grid container justify="flex-end">
                <StyledLink to={url}>
                  <Spacer mr={10}>{postType !== "career" ? "Read Article" : "See Job Posting"}</Spacer>
                  <div>{getIcon("Chevron")}</div>
                </StyledLink>
              </Grid>
            </PostDetails>
          </Grid>
        </Grid>
      </PostContainer>
      <Spacer mb={50} />
    </>
  )
}

const PostContainer = styled.div`
  border-radius: 20px 0 20px 20px;
  overflow: hidden;
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
  height: 175px;

  @media (min-width: 768px) {
    height: 250px;
  }
`

const Excerpt = styled.div`
  .excerpt-text {
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
`

const StyledLink = styled(Link)`
  color: ${colors.secondary};
  display: flex;
  text-transform: uppercase;

  &:visited {
    color: ${colors.secondary};
  }
`

const PostDetails = styled.div`
  background: linear-gradient(190deg, #E1E8EE 41.94%, rgba(225,232,238,0) 92.13%);
  height: 100%;
  padding: 30px;

  @media (min-width: 768px) {
    padding: 30px 90px 30px 30px;
  }
`

const Item = styled.div`
  color: ${colors.secondary};
`

export default PostPreview
