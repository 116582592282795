import React from "react"
import styled from "styled-components"
import MenuItems from "./MenuItems"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"

// import app components
import * as theme from "theme"
import { getUrlPath } from "utils"

const DesktopMenu = props => {
  return (
    <Menu {...props}>
      <MenuItems name="main">
        {items => {
          return items.map(
            ({ id, url, active, activeParent, label, childItems }) => (
              <MenuItem key={id}>
                {childItems && childItems.nodes.length ? (
                  <>
                    <MenuLink active={active} to={getUrlPath(url)}>
                      <Typography
                        color="inherit"
                        component="div"
                        variant="body2"
                        children={label}
                      />
                    </MenuLink>

                    <SubMenu className="sub-menu">
                      {childItems.nodes.map((o, i) => (
                        <SubMenuItem
                          key={i}
                          to={getUrlPath(o.url)}
                          activeClassName="active-item"
                        >
                          <Typography
                            color="inherit"
                            component="div"
                            children={o.label}
                          />
                        </SubMenuItem>
                      ))}
                    </SubMenu>
                  </>
                ) : (
                  <MenuLink active={active} to={getUrlPath(url)}>
                    <Typography
                      color="inherit"
                      component="div"
                      variant="body2"
                      children={label}
                    />
                  </MenuLink>
                )}
              </MenuItem>
            )
          )
        }}
      </MenuItems>
    </Menu>
  )
}

export default DesktopMenu

const Menu = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  display: flex;
  position: relative;

  [aria-current="page"] {
    color: ${theme.colors.secondary};

    .MuiTypography-root {
      font-family: GilroyBold;
    }
  }

  &:hover {
    .sub-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
  .sub-menu {
    opacity: 0;
    pointer-events: none;
  }
`

const MenuLink = styled(Link)`
  padding: 5px 10px;
  margin-left: 40px;
  color: ${theme.colors.primary};
  font-weight: ${props => props.active ? "bold" : "normal"};

  &:hover {
    color: ${theme.colors.secondary};
  }
`

const SubMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: -20px;
  background: ${theme.colors.primary};
  padding: 10px 0;

  .active-item {
    color: white;
  }
`

const SubMenuItem = styled(Link)`
  padding: 10px 30px;
  flex-shrink: 0;
  white-space: nowrap;
  color: #e4f2f5;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  > div {
    font-size: 14px;
  }
`
