import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"
import { AppBar } from "@material-ui/core"

import DesktopMenu from "components/menu/DesktopMenu"
import MobileMenu from "components/menu/MobileMenu"
import { Edges, Grid } from "components"
import Logo from "components/icons/logo.svg"
import { useStore } from "store"
import * as theme from "theme"

export default props => {
  const { path } = props

  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  return (
    <>
      <StyledAppBar position="static">
        <Edges size="lg">
          <Grid
            display="flex"
            justifycontent="space-between"
            alignitems="center"
            height={{ xs: 60, md: 100 }}
          >
            <LogoContainer>
              <Link
                to="/"
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              >
                <Logo />
              </Link>
            </LogoContainer>

            <DesktopMenuContainer>
              <DesktopMenu className="desktop-menu" />
            </DesktopMenuContainer>

            <HamburgerMenuContainer
              onClick={() => dispatch({ type: "TOGGLE_MENU" })}
            >
              <HamburgerMenu
                color={theme.colors.primary}
                isOpen={menu}
                width={18}
                height={15}
                strokeWidth={1}
                menuClicked={() => ""}
              />
            </HamburgerMenuContainer>
          </Grid>
        </Edges>
      </StyledAppBar>

      <MobileMenu />
    </>
  )
}

const StyledAppBar = styled(AppBar)`
  && {
    position: relative;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  }
`

const LogoContainer = styled.div`
  svg {
    max-width: 120px;
  }

  a {
    padding: 10px 0;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    svg {
      max-width: 100%;
    }
  }
`

const DesktopMenuContainer = styled.div`
  display: none;

  @media (min-width: ${theme.mui.breakpoints.values.lg}px) {
    display: block;
  }
`

const HamburgerMenuContainer = styled.div`
  padding: 15px;
  cursor: pointer;

  @media (min-width: ${theme.mui.breakpoints.values.lg}px) {
    display: none;
  }
`
