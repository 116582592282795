import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"

// import app components
import { Edges, Spacer } from "components"
import LogoOnly from "components/icons/gsk_logo_only.svg"

import * as theme from "theme"

export default () => {
  return (
    <Container textcolor={"#fff"} background={theme.colors.primary}>
      <Spacer pt={15} pb={15}>
        <Edges size="lg">
          <Grid container justify="space-between">
            <Grid item xs={9} md={9}>
              <Grid container alignitems="flex-start" style={{ height: "100%" }}>
                <Grid item xs={12} md={4}>Goldsmith Kwok Accounting Group</Grid>
                <Grid item xs={12} md={3}>
                  <Spacer mt={{ xs: 10, md: 0 }} mb={{ xs: 10, md: 0 }}>
                    <PlainList>
                      <li>1168 Hamilton St. #502</li>
                      <li>Vancouver, BC V6B 2S2</li>
                    </PlainList>
                  </Spacer>
                </Grid>
                <Grid item xs={12} md={3}>
                  <PlainList>
                    <li>
                      <a href="tel:6044248248">604 424 8248</a>
                    </li>
                    <li>
                      <a href="mailto:info@goldsmithkwok.com">info@goldsmithkwok.com</a>
                    </li>
                  </PlainList>
                </Grid>
                <Grid item xs={12} md={2}>
                  <PlainList>
                    <li>
                      <a href="/privacy-policy">Privacy Policy</a>
                    </li>
                  </PlainList>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={3} md={3} justify="flex-end">
              <LogoCont>
                <LogoOnly />
              </LogoCont>
            </Grid>
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled(({ background, textcolor, ...rest }) => <div {...rest} />)`
  background: ${props => props.background || theme.colors.primary};
  color: ${props => props.textcolor || "#fff"};
  font-size: 14px;
`

const PlainList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    color: #fff;
  }
`

const LogoCont = styled.div`
  align-items: center;
  color: #7B8084;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    align-items: flex-start;
    justify-content: flex-end;
  }
`