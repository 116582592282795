import React from "react"
import styled from "styled-components"

// import app components
import Header from "components/header"
import Footer from "components/footer"


export default props => {

  return (
    <>
      <Container>
        <div>
          <Header />
          {props?.children}
        </div>
        <Footer />
      </Container>

    </>
  )
}

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`