import React from "react"
import styled, { css } from "styled-components"
import MenuItems from "./MenuItems"
import { Link } from "gatsby"
import { Typography } from "@material-ui/core"

// import app components
import { useStore } from "store"
import * as theme from "theme"
import { getUrlPath } from "utils"

const MobileMenu = props => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  return (
    <Menu {...props} menuState={menu}>
      <MenuItems name="main">
        {items => {
          return items.map(({ id, url, active, activeParent, label }) => (
            <Item
              onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              key={id}
              to={getUrlPath(url)}
              className={active || activeParent ? "active" : ""}
            >
              <Title variant="subtitle1" children={label} />
            </Item>
          ))
        }}
      </MenuItems>
    </Menu>
  )
}

export default MobileMenu

const menuClose = css`
  transform: translateX(120%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateX(0);
  opacity: 1;
`

const Menu = styled.div`
  ${props => (!!props.menuState ? menuOpen : menuClose)}
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: ${theme.colors.primary};
  padding-top: 40px;
  top: 56px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    top: 64px;
  }
`

const ItemCSS = `
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  width: 100%;
  text-align: center;
`

const Item = styled(Link)`
  ${ItemCSS}
`

const Title = styled(Typography)`
  padding: 5px 0;
  color: white;
  text-transform: uppercase;
`
