import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { Button as MuiButton, CircularProgress } from "@material-ui/core"

import { Spacer } from "components"
import { colors } from "theme"
import { getIcon } from "utils"

export default ({ children, to, loading, icon, iconColor, ...rest }) => {
  if (to) {
    return (
      <StyledLink to={to}>
        <Button {...rest} loading={loading ? 1 : 0}>
          <CircularProgress className="btn-loader" size={25} />
          {icon && (
            <Spacer className="Button_icon" mr={10}>
              {getIcon(icon)}
            </Spacer>
          )}
          <span className="btn-children">{children}</span>
        </Button>
      </StyledLink>
    )
  } else {
    return (
      <Button {...rest} loading={loading ? 1 : 0}>
        <CircularProgress className="btn-loader" size={25} />
        <span className="btn-children">{children}</span>
      </Button>
    )
  }
}

const StyledLink = styled(Link)`
  text-transform: initial;
`

const Button = styled(MuiButton)`
  && {
    position: relative;
    background: ${props => props.type === "primary" ? "#4A4F54" : "#486E92"};
    border-radius: 5px;
    border-top-left-radius: 0px;
    color: #fff;
    padding: 10px 30px 10px 30px;
    position: relative;
    overflow: hidden;
    text-transform: initial;

    &:before {
      background: ${props => props.type === "primary" ? "#7B8084" : colors.alt};;
      border-radius: 50%;
      content: "";
      height: 200%;
      left: 0px;
      position: absolute;
      max-width: 100px;
      min-width: 80px;
      top: -1px;
      width: 50%;
      z-index: 1;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 45px;
      top: 0px;
      background: ${props => props.type === "primary" ? "#7B8084" : colors.alt};;
      z-index: 1;
    }

    span {
      z-index: 2;
      position: relative;
    }

    .btn-loader {
      position: absolute;
      opacity: 0;
      z-index: 1;
      pointer-events: none;
      left: calc(50% - 12.5px);
    }

    .Button_icon {
      transform: rotate(180deg);
    }

    ${({ loading }) =>
      loading &&
      css`
        .btn-children {
          opacity: 0;
        }
        .btn-loader {
          opacity: 1;
        }
      `}
  }
`
