import React from "react"
import styled from "styled-components"

// import app components
import { Textarea, Edges } from "components"
import { colors } from "theme"

const Content = (props) => {
  const {
    columns,
    twoColumnList,
    threeColumnList,
    fourColumnList
  } = props

  const columnInt = parseInt(columns);

  function List(props) {
    switch (columnInt) {
      case 2:
        return (
          twoColumnList.map((o, i) => {
            return (
              <Row i={i} columnstyle={o.columnStyle}>
                <Col>{o.column1}</Col>
                <Col>{o.column2}</Col>
              </Row>
            )
          })
        )
      case 3:
        return (
          threeColumnList.map((o, i) => {
            return (
              <Row i={i} columnstyle={o.columnStyle}>
                <Col><Textarea content={o.column1} /></Col>
                <Col><Textarea content={o.column2} /></Col>
                <Col><Textarea content={o.column3} /></Col>
              </Row>
            )
          })
        )
      case 4:
        return (
          fourColumnList.map((o, i) => {
            return (
              <Row i={i} columnstyle={o.columnStyle}>
                <Col>{o.column1}</Col>
                <Col>{o.column2}</Col>
                <Col>{o.column3}</Col>
                <Col>{o.column4}</Col>
              </Row>
            )
          })
        )
      default:
        return null
    }
  }

  return (
    <Container>
      <Edges size="lg">
        <List />
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 60px;
`

const Row = styled(({ columnstyle, ...rest }) => (
  <div {...rest} />
))`
  background: ${props => props.columnstyle === "head" ? colors.secondary : "transparent"};
  color: ${props => props.columnstyle === "head" ? "#fff" : colors.text.primary };
  display: flex;
  flex-flow: row wrap;
  font-weight: ${props => props.columnstyle === "bold" ? props.columnstyle : "normal"};

  @media (min-width: 768px) {
    flex-flow: row;
  }
`

const Col = styled.div`
  flex-basis: 100%;
  padding: 5px;

  @media (min-width: 768px) {
    flex-basis: 25%;
  }

  p {
    margin: 0px !important;
  }
`

export default Content
