import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import { Edges, Spacer } from "components"
import { PostPreview } from "components"
import * as theme from "theme"

import { getIcon } from "utils"

const NewsFeed = props => {
  const { nodes } = usePostQuery()
  const displaySvgIcon = false

  return (
    <Edges size="md">
      <Spacer mt={60} />
      <Grid container>
        <Grid item xs={12}>
          {nodes &&
            nodes.map(o => {
              return (
                <PostPreview
                  date={o.date}
                  headline={o.title}
                  key={o.id}
                  url={o.uri}
                  postType="career"
                />
              )
            })}

          <Spacer mt={100} mb={100}>
            <Flex justify="center">
              {displaySvgIcon && (
                <SvgCircleCont>
                  {getIcon("HorizontalCircleGroup")}
                </SvgCircleCont>
              )}
            </Flex>
          </Spacer>
        </Grid>
      </Grid>
    </Edges>
  )
}

const SvgCircleCont = styled.div`
  display: inline-flex;
  max-width: 96px;
`

const Flex = styled(({ align, justify, ...rest }) => (
  <div {...rest} />
))`
  align-items: ${props => props.align || "flex-start"};
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => props.justify || "flex-start"};
`

const usePostQuery = () => {
  const { allWpCareer } = useStaticQuery(
    graphql`
      query {
        allWpCareer {
          nodes {
            id
            title
            uri
            date
          }
        }
      }
    `
  )
  return allWpCareer
}

export default NewsFeed
