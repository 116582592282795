import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

export default createTheme({
  typography: {
    fontFamily: "GilroyRegular, Arial, sans-serif",
    fontFamilyAlt: "TiemposTextRegular",
    h1: {
      fontFamily: "GilroyBold, Arial, sans-serif",
      fontSize: "28px",
      fontWeight: "bold",
      lineHeight: "34px",
      letterSpacing: "-0.01em",
      color: colors.primary
    },
    h2: {
      fontFamily: "TiemposTextRegular",
      fontSize: "21px",
      lineHeight: " 30px",
      color: colors.secondary
    },
    h3: {
      fontFamily: "GilroyBold, Arial, sans-serif",
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "22px"
    },
    h4: {},
    h5: {},
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {
      fontSize: "16px",
      [breakpoints.up("md")]: {
        fontSize: "16px"
      },
      lineHeight: "22px",
      fontWeight: 400
    },
    body2: {
      fontSize: "14px",
      lineHeight: 1.5,
      fontWeight: 400
    },
    caption: {},
    button: {},
    circleRegular: {
      fontSize: "18px",
      lineHeight: "22px"
    },
    circleTitle: {
      fontSize: "22px",
      lineHeight: "24px",
      fontWeight: "bold"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1440
    }
  },
  gutter: {
    xs: "5px",
    sm: "10px",
    md: "20px",
    lg: "30px",
    xl: "60px"
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "0.81rem"
      }
    }
  }
})
