import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

// import app components
import { BackgroundImage } from "components"
import * as theme from "theme"

const Banner = props => {
  const {
    backgroundColor,
    image,
  } = props

  return (
    <Container backgroundColor={backgroundColor}>
      {image && <BackgroundImage image={image} />}
    </Container>
  )
}

const Container = styled(({ backgroundColor, ...rest }) => <div {...rest} />)`
  background-color: ${theme.colors.secondary};
  height: 100px;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    height: 160px;
  }
`

export default Banner
