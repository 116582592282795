import React from "react"

// import app components
import Edges from "components/edges"
import Grid from "components/grid"
import Button from "components/button"
import Textarea from "components/textarea"
import Spacer from "components/spacer"
import { getIcon } from "utils"

const CallToAction = props => {
  const { button, content } = props

  return (
    <Spacer
      mt={{ xs: 30, sm: 60 }}
      mb={{ xs: 30, sm: 60 }}
    >
      <Edges size="lg">
        <Grid display="flex" flexwrap="wrap">
          <div>{getIcon("VerticalCircleGroup")}</div>
          <Spacer mr={{xs: 0, sm: 60}} />
          <div>
            <Textarea content={content} />
            <Spacer mt={20} />
            <Button type={button.type}>This is a button</Button>
          </div>
        </Grid>
      </Edges>
    </Spacer>
  )
}

export default CallToAction
