import React from "react"
import styled from "styled-components"

import * as theme from "theme"

export default function Grid(props) {
  return <Container {...props} />
}

// translate shortcode to css value
const supportedProps = [
  { paddingTop: "padding-top" },
  { paddingRight: "padding-right" },
  { paddingBottom: "padding-bottom" },
  { paddingLeft: "padding-left" },
  { padding: "padding" },
  { marginTop: "margin-top" },
  { marginRight: "margin-right" },
  { marginBottom: "margin-bottom" },
  { marginLeft: "margin-left" },
  { margin: "margin" },
  { display: "display" },
  { alignitems: "align-items" },
  { justifycontent: "justify-content" },
  { flexDirection: "flex-direction" },
  { flexwrap: "flex-wrap" },
  { background: "background" },
  { height: "height" },
  { minheight: "min-height" },
  { maxHeight: "max-height" },
  { width: "width" },
  { minWidth: "min-width" },
  { maxWidth: "min-width" },
  { position: "position" }
]

const Container = styled(({ ...rest }) => <div {...rest} />)`
  ${props => {
    const css = supportedProps
      .map(prop => {
        const key = Object.keys(prop)

        let value = props[key]

        // render nothing if no value is supplied
        if (!value) {
          return null
        }

        // if single value is supplied then wrap into object with lowest breakpoint
        if (typeof value !== "object") {
          value = { xs: value }
        }

        // get css attribute
        const cssAttr = prop[key[0]]

        return `${createBreakpointStyles(value, cssAttr)}`
      })
      .join("")
      .replace(/,/g, "")

    return css
  }}
`

export function createBreakpointStyles(value, cssAttr) {
  return Object.keys(value).map(key => {
    const cssValue =
      typeof value[key] === "string" || value[key] instanceof String
        ? value[key]
        : `${value[key]}px`

    return `@media screen and (min-width: ${theme.mui.breakpoints.values[key]}px){
      ${cssAttr}: ${cssValue};
    }`
  })
}
