import React from "react"
import styled from "styled-components"

// import app components
import Textarea from "components/textarea"
import Edges from "components/edges"
import Spacer from "components/spacer"

const Content = (props) => {
  const { content, margin, width } = props

  return (
    <Container>
      {margin && (
        <Spacer mt={margin} />
      )}
      <Edges size={width ? width : "md"}>
        <Textarea content={content} />
      </Edges>
    </Container>
  )
}

const Container = styled.div``

export default Content
