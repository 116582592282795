import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { GatsbyImage } from "gatsby-plugin-image";

// import app components
import {
  Edges,
  Headline,
  Spacer,
  Textarea
} from "components"
import * as theme from "theme"

const TeamMembers = props => {
  const {
    teamMember
  } = props

  return (
    <Spacer
      pt={{ xs: 30, sm: 60 }}
      pb={{ xs: 30, sm: 60 }}
    >
      <Edges size="md">
        {teamMember.map((o, i) => {
          return (
            <MemberRow key={i} mb={80}>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <TeamMemberCont container className="image-grid-cont">
                    <CircularContent>
                      {o.image && (
                        <MemberImage
                          className="MemberImage"
                        >
                          <GatsbyImage
                            image={o?.image?.localFile?.childImageSharp?.gatsbyImageData}
                            alt={`Headshot of ${o?.nameAndTitle}`}
                            id={i} />
                        </MemberImage>
                      )}
                    </CircularContent>
                  </TeamMemberCont>
                </Grid>
                <Spacer ml={{ md: 40, lg: 80 }} />
                <Grid item md={8}>
                  <Spacer mt={{xs: 25, md: 0}} />
                  <HeadlineCont>
                    <Headline variant="h2">{o.nameAndTitle}</Headline>
                  </HeadlineCont>
                  <Spacer mb={25} />
                  <Spacer pl={{xs: 10, md: 0}} pr={{xs: 10, md: 0}}>
                    <Textarea content={o.text} />
                  </Spacer>
                </Grid>
              </Grid>
            </MemberRow>
          );
        })}
      </Edges>
    </Spacer>
  );
}

const CircularContent = styled.div`
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-flow: row wrap;
  height: 185px;
  justify-content: center;
  padding: 0;
  text-align: center;
  width: 190px;
`

const MemberRow = styled(({ colouroverlay, ...rest }) => (
  <Spacer {...rest} />
))`

  &:nth-of-type(1n+0) {
    .gatsby-image-wrapper {
      padding: 5px;

      &:before {
        background-color: #215682;
      }
    }
  }

  &:nth-of-type(2n+0) {
    .gatsby-image-wrapper {
      &:before {
        background-color: #956038;
      }
    }
  }

  &:nth-of-type(3n+0) {
    .gatsby-image-wrapper {
      &:before {
        background-color: #739c90;
      }
    }
  }

  &:nth-of-type(4n+0) {
    .gatsby-image-wrapper {
      &:before {
        background-color: #d1cabb;
      }
    }
  }
`

const MemberImage = styled.div`
  .gatsby-image-wrapper {
    border-radius: 50%;
    position: relative;

    &:before {
      border-radius: 50%;
      content: "";
      height: 100%;
      filter: contrast(1.2);
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5;
    }

    img {
      filter: saturate(0.1);
    }
  }
`

const TeamMemberCont = styled(Grid)`
  justify-content: center;
  
  @media (min-width: ${theme.mui.breakpoints.values.lg}px) {
    justify-content: flex-end;
  }
`

const HeadlineCont = styled.div`
  text-align: center;

  @media (min-width: ${theme.mui.breakpoints.values.md}px) {
    text-align: left;
  }
`

export default TeamMembers
