import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components

const Headline = props => {
  const { textAlign, color, ...rest } = props

  return (
    <Container textalign={textAlign}>
      <StyledTypography color={color} textalign={textAlign} {...rest} />
    </Container>
  )
}

const Container = styled(({ underline, textalign, ...rest }) => (
  <div {...rest} />
))`
`

const StyledTypography = styled(({ textalign, color, ...rest }) => (
  <Typography {...rest} />
))`
  text-align: ${props => props.textalign};
  color: ${props => props.color};
`

export default Headline
