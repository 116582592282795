import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  body {
    font-size: 18px;
    line-height: 21px;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:visited {
      color: #333;
    }
  }

  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  p {
    margin-top: 0px;
  }
`
