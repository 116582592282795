import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

// import app components
import { Edges, Spacer } from "components"
import { PostPreview } from "components"
import * as theme from "theme"

import { getIcon } from "utils"

const postsPerPage = 5

const NewsFeed = props => {
  const { category } = props
  const { nodes } = usePostQuery()

  const activeCat = category[0].name

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [posts, setPosts] = useState([])
  const displaySvgIcon = false

  const availableCategories = []

  nodes.map(o => {
    return (
      o?.categories?.nodes &&
      o.categories.nodes.map(p => {
        if (!availableCategories.includes(p.name)) {
          return availableCategories.push(p.name)
        } else {
          return null
        }
      })
    )
  })

  useEffect(() => {
    getPosts(1, activeCat)
  }, [])

  const getPosts = (p, cat) => {
    // Checks to see if there's a specified category of posts to display.
    const categoryPosts =
      cat === `all`
        ? nodes
        : nodes.filter(
            o =>
              o?.categories?.nodes &&
              o.categories.nodes.find(p => p.name === cat)
          )

    const paginatedPosts = categoryPosts.slice(
      (p - 1) * postsPerPage,
      (p - 1) * postsPerPage + postsPerPage
    )

    setPageCount(Math.ceil(categoryPosts.length / postsPerPage))
    setPosts(paginatedPosts)
  }

  const truncate = theText => {
    if (theText) {
      return theText.length > 280 ? theText.substring(0, 270) + "..." : theText
    }
  }

  const handlePageChange = (e, v) => {
    setPage(v)
    getPosts(v, activeCat)
  }

  return (
    <Edges size="md">
      <Spacer mt={60} />
      <Grid container>
        <Grid item xs={12}>
          {nodes &&
            nodes.map(o => {
              return (
                <PostPreview
                  date={o.date}
                  headline={o.title}
                  image={o.acf.image}
                  key={o.id}
                  text={truncate(o.acf.text)}
                  url={o.uri}
                />
              )
            })}

          {pageCount > postsPerPage && (
            <PaginationCont>
              {/* <Pagination
                count={pageCount}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              /> */}
            </PaginationCont>
          )}

          <Spacer mt={100} mb={100}>
            <Flex justify="center">
              {displaySvgIcon && (
                <SvgCircleCont>
                  {getIcon("HorizontalCircleGroup")}
                </SvgCircleCont>
              )}
            </Flex>
          </Spacer>
        </Grid>
      </Grid>
    </Edges>
  )
}

const PaginationCont = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  width: 100%;

  ul li {
    margin-right: 10px;
  }

  .MuiPaginationItem-root {
    background: #f8f8f8;
    border: 0px;
  }

  .MuiPaginationItem-root.Mui-selected {
    background: ${theme.colors.secondary};
    border-radius: 0px;
    color: white;
  }
`

const SvgCircleCont = styled.div`
  display: inline-flex;
  max-width: 96px;
`

const Flex = styled(({ align, justify, ...rest }) => (
  <div {...rest} />
))`
  align-items: ${props => props.align || "flex-start"};
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => props.justify || "flex-start"};
`

const usePostQuery = () => {
  const { allWpPost } = useStaticQuery(
    graphql`{
  allWpPost {
    nodes {
      id
      title
      uri
      date
      categories {
        nodes {
          name
          databaseId
        }
      }
      acf {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`
  )
  return allWpPost
}

export default NewsFeed
