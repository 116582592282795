import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";

// import app components
import { Edges, Grid, Spacer } from "components"
import * as theme from "theme"

const Clients = props => {
  const {
    clients,
    customOrSequentialColourOverlay,
    line1,
    line2
  } = props
  const [backgroundFilter, setBackgroundFilter] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);
  const [clientsRandom, setClientsRandom] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [loaded, setLoaded] = useState(false);

  function randomize(a, b) {
    return Math.random() - 0.5;
  }

  function getWindowDimensions() {
    if (typeof window !== "undefined") {
      const { innerWidth: width, innerHeight: height } = window;

      return {
        width,
        height
      };
    }
  }

  useEffect(() => {
    setLoaded(true)

    let clientData = [].concat(clients).sort(randomize)
    clientData.map(o => {
      const delay = 250 + Math.floor(Math.random(100) * 1000);
      o.delay = delay

      return clientData
    })

    setClientsRandom(clientData)
  }, [clients])

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClientClick = (client) => {
    if (windowDimensions.width < 768) {
      setBackgroundFilter(true)
      setSelectedClient(client)
    }
  }

  const handleBackgroundClick = () => {
    if (windowDimensions.width < 768) {
      setBackgroundFilter(false)
      setSelectedClient(null)
    }
  }

  return <>
    <BackgroundCover
      backgroundfilter={backgroundFilter}
      onClick={handleBackgroundClick}
    />

    <GridWrapper
    >
      <Grid
        background={theme.colors.tertiary}
        minheight={{ xs: "100vh", md: "calc(100vh - 200px)" }}
      >
        <Spacer
          pt={{ xs: 30, sm: 60 }}
          pb={{ xs: 30, sm: 60 }}
        >
          <Edges size="lg">
            <Grid
              display="flex"
              justifycontent={{ xs: "center", lg: "flex-start" }}
              flexwrap="wrap"
            >
              <Item>
                <CircularContent>
                  <CircularTypography>
                    <FadeInFont className={loaded && "loaded"}>
                      <div>{line1}</div>
                      <div>{line2}</div>
                    </FadeInFont>
                  </CircularTypography>
                </CircularContent>
              </Item>
              {clientsRandom.map((o, i) => {
                return (
                  <Item
                    backgroundfilter={backgroundFilter}
                    className={`client-item`}
                    colouroverlay={o.colourOverlay}
                    clientactive={selectedClient === i}
                    customorsequential={customOrSequentialColourOverlay}
                    fadedelay={o.delay}
                    key={i}
                    onClick={() => handleClientClick(i)}
                  >
                    <ClientText
                      className="client-text"
                      name={o?.name ? o?.name : "" }
                      text={o?.text ? o?.text : ""}
                      headline={o?.title ? o?.title : ""}
                    >
                      <Spacer mb={10}>
                        <div>
                          {`"` + o?.text + `"`}
                        </div>
                      </Spacer>
                      <div className="client-text__name">
                        {o?.name}
                      </div>
                      <div className="client-text__title">
                        {o?.title}
                      </div>
                    </ClientText>
                    <ClientImage
                      className="ClientImage"
                    >
                      {o.image && (
                        <GatsbyImage
                          image={o?.image?.localFile?.childImageSharp?.gatsbyImageData}
                          alt={o?.name ? "Profile photo of " + o.name : ""}
                          id={i} />
                      )}
                    </ClientImage>
                  </Item>
                );
              })}
            </Grid>
          </Edges>
        </Spacer>
      </Grid>
    </GridWrapper>
  </>;
}

const BackgroundCover = styled(({ backgroundfilter, ...rest }) => (
  <div {...rest} />
))`
  background: transparent;
  height: 100vh;
  position: fixed;
  top: 0;
  visibility: ${props => props.backgroundfilter ? "visible" : "hidden" };
  width: 100vw;
  transform: translate3d(0,0,0);
  z-index: 2;
`

const Item = styled(({ clientactive, fadedelay, customorsequential, colouroverlay, backgroundfilter, ...rest }) => (
  <div {...rest} />
))`
  align-items: center;
  animation-name: fadeIn;
  animation-duration: 1250ms;
  animation-delay: ${props => props.fadedelay}ms;
  animation-fill-mode: forwards;
  box-sizing: content-box;
  display: flex;
  margin: 0 10px 20px 10px;
  min-height: 235px;
  opacity: 0;
  width: 235px;
  position: static;
  z-index: ${props => props.clientactive ? "20" : "1"};

  &:before {
    background: rgba(0,0,0,0.4);
    content: "";
    height: 100vh;
    left: 0px;
    position: fixed;
    pointer-events: none;
    top: 0;
    visibility: ${props => props.backgroundfilter && props.clientactive ? "visible" : "hidden" };
    width: 100vw;
    transform: translate3d(0,0,0);
    z-index: 1;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .gatsby-image-wrapper {
    padding: 5px;

    &:before {
      background-color: ${props => props.customorsequential === "custom" ? props.colouroverlay : "#215682" };
    }
  }

  ${props => props.customorsequential === "sequential" && (`
    &:nth-of-type(2n+0) {
      .gatsby-image-wrapper {
        &:before {
          background-color: #956038;
        }
      }
    }
  
    &:nth-of-type(3n+0) {
      .gatsby-image-wrapper {
        &:before {
          background-color: #739c90;
        }
      }
    }
  
    &:nth-of-type(4n+0) {
      .gatsby-image-wrapper {
        &:before {
          background-color: #d1cabb;
        }
      }
    }
  `)}

  .client-text {
    background: #fff;
    border-radius: 12px;
    opacity: ${props => props.clientactive ? "1" : "0"};
    font-size: 14px;
    line-height: 18px;
    padding: 32px 16px;
    position: absolute;
    text-align: center;
    width: 218px;
    transform: translate3d(0,0,0);
    z-index: 5;

    .client-text__name {
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
    }

    &:after {
      content: "";
      background: black;
      height: 2px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 15px;
      width: 25%;
    }
  }

  .ClientImage, .client-text {
    transition: opacity 300ms, transform 300ms;
  }

  .ClientImage {
    position: absolute;
    opacity: 1;
    transform: translateZ(0px) perspective(500px);
    width: 250px;
  }

  @media (min-width: 900px) {
    &:hover {
      .client-text {
        opacity: 1;
        transform: translateZ(0px) perspective(500px);
      }
  
      .ClientImage {
        opacity: 0;
        transform: translateZ(-50px) perspective(500px);
      }
    }
  }

  @media (min-width: 1260px) {
    flex-basis: calc(20% - 20px);
    width: auto;
  }
`

const CircularContent = styled.div`
  align-items: center;
  background: ${theme.colors.primary};
  border-radius: 50%;
  color: #fff;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  justify-content: center;
  padding: 0px 20px;
  text-align: center;
  width: 100%;
`

const CircularTypography = styled.div`
  ${theme.mui.typography.circleTitle};
`

const FadeInFont = styled.div`
  opacity: 0;
  transition: opacity 500ms;
  transition-delay: 1000ms;

  &.loaded {
    opacity: 1;
  }

  div:first-child {
    margin-bottom: 10px;
  }
`

const ClientImage = styled.div`
  .gatsby-image-wrapper {
    border-radius: 50%;
    position: relative;

    &:before {
      border-radius: 50%;
      content: "";
      height: 100%;
      filter: contrast(1.2);
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5;
    }

    img {
      filter: saturate(0.1);
    }
  }
`

const GridWrapper = styled.div`
  &.overlay-active {
    position: relative;
    z-index: 11;
  }
`

const ClientText = styled(({ clientactive, ...rest }) => (
  <div {...rest} />
))`
  background: #fff;
  border-radius: 12px;
  font-size: 14px;
  line-height: 18px;
  padding: 32px 16px;
  position: relative;
  text-align: center;
  position: relative;

  .client-text__name {
    font-weight: bold;
    font-size: 18px;
  }

  &:after {
    content: "";
    background: black;
    height: 2px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 15px;
    width: 25%;
  }

  &:before {
    bottom: -20px;
    left: 30px;
    position: absolute;
    border-left: 1px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    content: "";
    height: 0;
    width: 0;
  }
`

export default Clients
