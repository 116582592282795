import React from "react"

import VerticalCirclesIcon from "../icons/vertical-circles.svg"
import VerticalCirclesWhiteIcon from "../icons/vertical-circles-white.svg"
import HorizontalCirclesIcon from "../icons/horizontal-circles.svg"
import ChevronIcon from "../icons/chevron.svg"

const getIcon = name => {
  switch (name) {
    case "HorizontalCircleGroup":
      return <HorizontalCirclesIcon />

    case "VerticalCircleGroup":
      return <VerticalCirclesIcon />
    
    case "VerticalCircleGroupWhite":
      return <VerticalCirclesWhiteIcon />

    case "Chevron":
      return <ChevronIcon />

    default:
  }
}

export default getIcon
