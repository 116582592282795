import React from "react"
import styled from "styled-components"

// import app components
import {
  BackgroundImage,
  Edges,
  Grid,
  Spacer,
  Textarea
} from "components"

const TextImage = props => {
  const { text, image } = props

  return (
    <Spacer
      mt={{ xs: 30, sm: 60 }}
      mb={{ xs: 30, sm: 60 }}
    >
      <Edges size="lg">
        <Grid display="flex" flexwrap="wrap">
          <Grid width={{ xs: "100%", md: "50%" }} paddingRight={{ md: 30 }}>
            <Textarea content={text} />
          </Grid>

          <Grid
            position="relative"
            height={{ xs: "40vw", md: "auto" }}
            width={{ xs: "100%", md: "50%" }}
          >
            <BackgroundImage image={image} />
          </Grid>
        </Grid>
      </Edges>
    </Spacer>
  )
}

const Container = styled.div`
`


export default TextImage
