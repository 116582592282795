import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import {
  BackgroundImage,
  Button,
  Spacer,
  Textarea
} from "components"
import { colors } from "theme"

const NewsPost = props => {
  const {
    author,
    categories,
    date,
    image,
    headline,
    text,
    url,
    ...rest
  } = props

  const formattedDate = new Date(date)
    .toDateString()
    .split(" ")
    .slice(1)
    .join(" ")

  return (
    <Container {...rest}>
      <Flex>
        <PostLeft>
          <Link to={url}>
            <ImageContainer>
              {image && <BackgroundImage image={{ image }} />}
            </ImageContainer>
          </Link>
        </PostLeft>

        <PostRight>
          {headline && (
            <Spacer mb={20}>
              <Link to={url}>
                <Typography variant="h5" children={headline} />
              </Link>
            </Spacer>
          )}

          {categories &&
            categories.nodes.map((o, i) => {
              return (
                <span key={i}>
                  {o.name}
                  {i < categories.nodes.length - 1 ? ", " : ""}
                </span>
              )
            })}
          <Excerpt>
            <Spacer mt={20} mb={20}>
              <Textarea className="excerpt-text" content={text} />
            </Spacer>
          </Excerpt>

          <PostDetails>
            {author && (
              <Item>
                by{" "}
                {author.name
                  ? author.name
                  : author.nickname
                  ? author.nickname
                  : "Canada Abroad"}
              </Item>
            )}
            <Spacer ml={7} mr={7}>
              &#8226;
            </Spacer>
            {date && <Item>{formattedDate}</Item>}
          </PostDetails>

          <Link to={url}>
            <ReadMore
              textColor={`#fff`}
              title={`Read Article`}
            />
          </Link>
        </PostRight>
      </Flex>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
`

const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
`

const PostLeft = styled.div`
  flex-basis: 100%;
  min-height: 250px;

  @media(min-width: 1200px) {
    flex-basis: 45%;
    min-height: 0px;
  }
`

const Excerpt = styled.div`
  .excerpt-text {
    p {
      font-size: 16px !important;
    }
  }
`

const PostDetails = styled.div`
  display: flex;
  font-size: 14px;
`

const Item = styled.div`
  color: ${colors.secondary};
`

const PostRight = styled.div`
  background: #f8f8f8;
  flex-basis: 100%;
  padding: 30px 15px;

  @media(min-width: 1200px) {
    flex-basis: 55%;
    padding: 45px 60px;
  }
`

const ReadMore = styled(Button)`
  font-size: 22px;
  line-height: 22px;
  margin-top: 30px;

  && {
    font-weight: bold;
  }
`

export default NewsPost
