import React from "react"
import styled from "styled-components"

// import app components
import {
  Edges,
  Spacer,
  Textarea
} from "components"
import { colors } from "theme"
import * as theme from "theme"

const PageIntro = props => {
  const {
    content,
    orientation,
    subHeading,
    subheadingType,
  } = props

  return (
    <Spacer
      mt={{ xs: 30, sm: 50 }}
      mb={{ xs: 30, sm: 50 }}
    >
      <Edges size="md">
        <Flex orientation={orientation}>
          <IntroLeft styledtext={subheadingType} className="IntroLeft" orientation={orientation}>
            {subHeading && (
              <Textarea className="IntroLeft__Textarea" content={subHeading} />
            )}
          </IntroLeft>
          <IntroRight orientation={orientation}>
            {content && <Textarea content={content} />}
          </IntroRight>
        </Flex>
      </Edges>
    </Spacer>
  )
}

const Flex = styled(({ orientation, ...rest }) => (
  <div {...rest} />
))`
  display: flex;
  flex-direction: ${props => props.orientation || "row"};
  flex-direction: column-reverse;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-flow: row;
  }
`

const IntroLeft = styled(({ styledtext, ...rest }) => (
  <div {...rest} />
))`
  ${props => props.styledtext === "styled" ? `
  color: ${colors.text.secondary};
  margin-top: 30px;
  flex-basis: 100%;

  .IntroLeft__Textarea {
    p {
      font-size: 21px;
      font-family: "TiemposTextRegular";
      line-height: 30px;
    }

    a {
      color: #7ba0c4;
    }
  }
  ` : null}

  @media (min-width: 768px) {
    flex-basis: 350px;
    margin-top: 0px;
    margin-bottom: ${props =>
      props.orientation === "column" ? theme.mui.gutter.lg :
      "0px"
    };
  }
`

const IntroRight = styled(({ orientation, ...rest }) => (
  <div {...rest} />
))`
  flex-basis: 100%;

  @media (min-width: 768px) {
    flex-basis: calc(70% - ${props =>
      props.orientation === "column" ? "0px" :
      theme.mui.gutter.md
    });
    margin-left: ${props =>
      props.orientation === "column" ? "0px" :
      "80px"
    };
  }
`

export default PageIntro
