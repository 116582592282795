const colors = {
  primary: "#333",
  secondary: "#215682",
  tertiary: "#F5F5F3",
  alt: "#7BA0C4",
  alt2: "#91B1CF",
  alt3: "#D1CCBD",
  success: "green",
  error: "red",
  text: {
    primary: "#333",
    secondary: "#215682",
  }
}

export default colors
